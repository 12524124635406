import { Link } from "gatsby"
import React, { useState } from "react"

const Footer = () => {
  const [mail, setMail] = useState("")

  setTimeout(() => {
    setMail("hi@danielmielke.de")
  }, 1000)

  return (
    <footer
      className="py-8 mt-32 transition-all duration-500 origin-top bg-black "
      id="footer"
      style={{ willChange: "filter" }}
    >
      <div className="flex items-baseline justify-between content">
        <a href={`mailto:${mail}`} className="text-white opacity-90">
          mail: {mail}
        </a>
      </div>
    </footer>
  )
}

export default Footer
