import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { FormattedText } from "./Content"

const Intro = ({ data, children, smallHeadline }) => {
  return (
    <section
      className={`flex flex-col-reverse py-8 sm:py-12 lg:py-24 md:flex-row md:space-x-4 md:justify-between ${
        data?.image ? "md:items-center" : ""
      }`}
    >
      <div>
        <h1
          className={`mb-3 font-extrabold sm:mb-4 lg:mb-6 ${
            smallHeadline
              ? "text-3xl md:text-4xl"
              : "text-4xl sm:text-5xl lg:text-7xl"
          }`}
        >
          {data.headline}
        </h1>
        {data.subline && (
          <div className={`max-w-xl text-xl prose lg:text-2xl`}>
            {data.subline.raw ? (
              <FormattedText raw={data.subline.raw} />
            ) : (
              data.subline
            )}
          </div>
        )}
        {children}
      </div>
      {data?.image && (
        <GatsbyImage
          className="h-auto max-w-full mb-8 w-44 md:w-64 md:mb-0 justify-self-end"
          loading="lazy"
          alt=""
          image={data.image.gatsbyImageData}
        />
      )}
    </section>
  )
}

export default Intro
