import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const ProjectList = ({ data, showAllProjectsLink }) => {
  return (
    <section className="py-12">
      <ul className="grid grid-cols-1 gap-12 md:gap-16 md:grid-cols-2">
        {data.map((project, i) => (
          <ProjectItem key={i} data={project} />
        ))}
      </ul>

      {showAllProjectsLink && (
        <Link
          to="/work"
          className="inline-flex items-end mt-16 text-3xl md:text-4xl text-red group"
        >
          <span className="font-extrabold underline">See all projects</span>
          <span
            aria-hidden
            className="inline-block ml-2 font-extrabold leading-none transition-transform duration-300 ease-in-out transform group-hover:translate-x-2"
          >
            →
          </span>
        </Link>
      )}
    </section>
  )
}

const ProjectItem = ({ data }) => {
  const { title, mainImage, tag, slug, shortDescription } = data.node || data

  return (
    <li className="relative">
      <Link to={`/work/${slug}`} className="flex w-full rounded-xl">
        <article className="flex flex-col w-full">
          <header className="mb-3 text-xl sm:text-2xl">
            <h2 className="inline-block font-extrabold">{title}</h2>
            <span className="">
              {" - "} {shortDescription}
            </span>
          </header>

          {mainImage.file.url.substr(-3) === "gif" ? (
            <div
              className="relative w-full overflow-hidden rounded-lg"
              style={{ paddingBottom: "52.5%" }}
            >
              <img
                src={mainImage.file.url + "?w=600"}
                alt={mainImage.title || ""}
                className="inset-0 z-10 object-cover w-full h-full overflow-hidden rounded-lg"
                style={{ position: "absolute" }}
                loading="lazy"
                decoding="async"
              />
              <GatsbyImage
                alt={""}
                image={mainImage.gatsbyImageData}
                width={500}
                height={365}
                className="z-0 overflow-hidden rounded-lg"
                style={{ position: "absolute" }}
                aria-hidden
              />
            </div>
          ) : (
            <div
              className="relative w-full rounded-lg"
              style={{ paddingBottom: "52.5%" }}
            >
              <GatsbyImage
                alt={mainImage.title || ""}
                image={mainImage.gatsbyImageData}
                width={500}
                height={365}
                className="inset-0 z-0 rounded-lg"
                style={{ position: "absolute" }}
              />
            </div>
          )}

          <div className="flex items-baseline justify-between pl-0.5 py-6 border-b border-black border-opacity-10">
            <span className="text-sm font-extrabold leading-none tracking-wide uppercase">
              {tag.title}
            </span>
            <div
              aria-hidden
              className="flex items-center justify-center h-10 px-6 font-bold transition-colors duration-300 ease-in-out border-2 border-black rounded-2xl border-opacity-80 hover:bg-black hover:text-white hover:text-opacity-95"
            >
              Details
            </div>
          </div>
        </article>
      </Link>
    </li>
  )
}

export default ProjectList
