import { Link } from "gatsby"
import React from "react"

const Logo = ({ onClick }) => {
  return (
    <Link
      onClick={onClick}
      to="/"
      className="relative z-30 text-2xl font-bold rounded-lg filter"
      aria-label="home"
    >
      d.mielke
    </Link>
  )
}

export default Logo
