import React, { useEffect } from "react"
// import anime from "animejs/lib/anime.es.js"

const MenuIcon = ({ isToggled }) => {
  return (
    <div
      aria-hidden
      className={`relative h-10 w-10 no-reduced-motion:transition-transform no-reduced-motion:duration-200 ease-in-out transform hover:scale-110`}
    >
      <div
        className={`absolute transform-gpu no-reduced-motion:transition-all no-reduced-motion:duration-200 ease-in-out w-10 h-1.5 ${
          isToggled ? "rotate-45 bg-white" : "bg-black"
        }`}
        style={{ top: isToggled ? "1.0625rem" : "0.5rem" }}
      />
      <div
        className={`absolute transform-gpu no-reduced-motion:transition-all no-reduced-motion:duration-200 ease-in-out w-10 h-1.5 ${
          isToggled ? "-rotate-45 bg-white" : "bg-black"
        }`}
        style={{ bottom: isToggled ? "1.0625rem" : "0.5rem" }}
      />
    </div>
  )
}

export default MenuIcon
