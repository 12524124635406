/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useLocation } from "@reach/router"
import anime from "animejs/lib/anime.es.js"
import { useMediaQuery, useWindowResize } from "beautiful-react-hooks"
import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { FormattedText } from "./Content"

const Navigation = ({ isToggled, onElementClick }) => {
  const location = useLocation()

  const [height, setHeight] = useState(undefined)

  useEffect(() => {
    setHeight(window.innerHeight)
  }, [])

  useWindowResize(() => {
    setHeight(window.innerHeight)
  })

  const prefersNoReducedMotion = useMediaQuery(
    "(prefers-reduced-motion: no-preference)"
  )

  const items = [
    {
      title: "Home",
      href: "/",
    },
    {
      title: "Work",
      href: "/work",
    },
    {
      title: "About me",
      href: "/about-me",
    },
    {
      title: "Contact",
      href: "/contact",
    },
  ]

  useEffect(() => {
    isToggled
      ? // eslint-disable-next-line no-undef
        document.querySelector("html").classList.add("overflow-hidden")
      : // eslint-disable-next-line no-undef
        document.querySelector("html").classList.remove("overflow-hidden")

    prefersNoReducedMotion &&
      isToggled &&
      anime({
        targets: ".nav-item",
        translateY: ["70%", "0%"],
        opacity: [0, 1],
        easing: "easeOutQuart",
        duration: 400,
        delay: anime.stagger(50),
      })
  }, [isToggled])

  const navCustomContent = useStaticQuery(graphql`
    {
      contentfulNavigation {
        content {
          raw
        }
      }
    }
  `)

  return (
    <>
      <nav
        id="mainNav"
        className={`rounded-4xl rounded-tr-none sm:pl-12 lg:pl-20 rounded-br-none lg:rounded-tr-4xl lg:rounded-br-4xl absolute inset-0 sm:left-auto sm:pr-20 sm:top-0 sm:right-0 pb-8 pt-28 overflow-y-auto md:p-20 md:pr-40 font-extrabold text-white transform-gpu bg-red z-10 ${
          isToggled
            ? "translate-x-0 opacity-100 ease-out-quad duration-200 scale-100"
            : "pointer-events-none select-none no-reduced-motion:translate-x-12 opacity-0 duration-200 ease-in-quad no-reduced-motion:scale-95"
        }`}
        style={{ height }}
      >
        <div className="inline-flex flex-col justify-between h-full content">
          <ul className="mb-10 space-y-8">
            {items.map((item, i) => (
              <li className="block nav-item" key={i}>
                <Link
                  to={item.href}
                  className="inline-block text-5xl font-extrabold leading-none transition-transform duration-200 ease-in-out transform border-b-2 border-transparent md:text-7xl hover:border-white"
                  tabIndex={isToggled ? "0" : "-1"}
                  onClick={onElementClick}
                  data-active={
                    item.title === "Work" &&
                    location.pathname.substring(1, 5) === "work"
                  }
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
          <div
            className={`max-w-sm text-white ${
              isToggled ? "visible" : "invisible"
            }`}
          >
            <FormattedText
              raw={navCustomContent.contentfulNavigation.content.raw}
            />
          </div>
        </div>
      </nav>

      <div
        aria-hidden
        className={`fixed inset-0 z-0 backdrop-filter transition-all duration-300 ${
          isToggled && prefersNoReducedMotion
            ? "sm:backdrop-blur-md cursor-pointer"
            : "pointer-events-none"
        }`}
        style={{ willChange: "backdrop-filter" }}
        onClick={() => onElementClick()}
      />
    </>
  )
}

export default Navigation
