import React from "react"
import ContentBlock from "./ContentBlock"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Intro from "./Intro"
import ProjectList from "./ProjectList"

const Content = ({ data, page }) => {
  const { content } = data.contentfulSeite || data.contentfulProjekt

  return content.map((section, i) => {
    if (section.__typename === "ContentfulContentIntro") {
      return (
        <Intro
          key={i}
          index={i}
          data={section}
          smallHeadline={section.smallHeadline}
        />
      )
    }

    if (section.__typename === "ContentfulContentTextBild") {
      return <ContentBlock index={i} key={i} data={section} />
    }

    if (section.__typename === "ContentfulContentProjektListe") {
      return (
        <ProjectList
          showAllProjectsLink={page === "home"}
          key={i}
          data={section.projects}
        />
      )
    }
  })
}

export function FormattedText({ raw }) {
  const options = {
    renderText: (text) =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  }

  return documentToReactComponents(JSON.parse(raw), options)
}

export default Content
