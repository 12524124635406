import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { FormattedText } from "./Content"

const ContentBlock = ({ data, index }) => {
  const {
    headline,
    image,
    imagePosition,
    isTextSmall,
    text,
    textTwoColumns,
  } = data

  const isImageOnTheSide =
    imagePosition === "links" || imagePosition === "rechts"

  return (
    <section
      className={`content-block flex flex-col ${
        isImageOnTheSide
          ? "lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center"
          : ""
      } ${!headline && !text ? "py-4" : "py-12"} `}
    >
      {(headline || text) && (
        <div
          className={
            headline && textTwoColumns && imagePosition === "unten"
              ? "md:grid md:grid-cols-2 md:gap-4 items-center"
              : `my-4`
          }
        >
          {headline &&
            (index === 0 ? (
              <h1 className="mb-3 text-3xl font-extrabold md:text-4xl">
                {headline}
              </h1>
            ) : (
              <h2 className="mb-3 text-3xl font-extrabold md:text-4xl">
                {headline}
              </h2>
            ))}
          {text && (
            <div
              className={`max-w-xl leading-normal ${
                isTextSmall ? "text-lg" : "text-xl lg:text-2xl"
              }`}
            >
              <FormattedText raw={text.raw} />
            </div>
          )}
        </div>
      )}

      {image &&
        (image.file.url.substr(-3) === "gif" ? (
          <div className="relative w-full overflow-hidden rounded-lg">
            <img
              src={image.file.url + "?w=900"}
              alt={image.title || ""}
              className="relative z-10 object-cover w-full h-full overflow-hidden rounded-lg"
              loading="lazy"
              decoding="async"
            />
            <GatsbyImage
              alt={""}
              image={image.gatsbyImageData}
              width={500}
              height={365}
              className="inset-0 z-0 overflow-hidden rounded-lg"
              style={{ position: "absolute" }}
              aria-hidden
            />
          </div>
        ) : (
          <GatsbyImage
            className={`w-full h-auto rounded-lg ${
              !isImageOnTheSide && (headline || text) ? "mt-10 mb-8" : ""
            } ${imagePosition === "links" ? "order-first" : ""}`}
            loading="lazy"
            alt={image?.title || ""}
            image={image.gatsbyImageData}
          />
        ))}
    </section>
  )
}

export default ContentBlock
