import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

import "focus-visible/dist/focus-visible.min.js"

import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/open-sans/800.css"
import "../styles/tailwind.css"
import "../styles/globals.css"
import { Helmet } from "react-helmet"
import Header from "./Header"
import Footer from "./Footer"
import { useLocation, navigate } from "@reach/router"
import { FormattedText } from "./Content"

const Layout = ({ children, title, description, className }) => {
  const { pathname } = useLocation()

  // Remove trailing slash in url
  useEffect(() => {
    if (pathname.substr(-1) === "/") {
      navigate(pathname.substring(0, pathname.length - 1), { replace: true })
    }
  }, [pathname])

  function removeTags(str) {
    if (str === null || str === "") return false
    else str = str.toString()

    return str.replace(/(<([^>]+)>)/gi, "")
  }

  const subline = useStaticQuery(graphql`
    {
      contentfulSeite(slug: { eq: "home" }) {
        content {
          ... on ContentfulContentIntro {
            subline {
              raw
            }
          }
        }
      }
    }
  `).contentfulSeite.content[0].subline.raw

  const homeMetaDescription = removeTags(
    documentToHtmlString(JSON.parse(subline))
  )

  return (
    <>
      <Helmet htmlAttributes={{ lang: "de" }}>
        <title>{title} | Daniel Mielke</title>
        <meta name="og:title" content={`${title} | Daniel Mielke`} />
        <link rel="shortcut icon" href="/favicon.png" type="image/png" />
        {description ? (
          <meta name="description" content={description} />
        ) : (
          <meta name="description" content={homeMetaDescription} />
        )}
        {description ? (
          <meta name="og:description" content={description} />
        ) : (
          <meta name="og:description" content={homeMetaDescription} />
        )}
      </Helmet>
      <div>
        <Header />
        <main
          id="main"
          className={`content pt-24 transition-all duration-500 ${
            className || ""
          }`}
        >
          {children}
        </main>
      </div>

      <Footer />
    </>
  )
}

Layout.propTypes = {
  className: PropTypes.string,
}

export default Layout
