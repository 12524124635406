import React, { useEffect, useState } from "react"
import Logo from "./Logo"
import MenuIcon from "./MenuIcon"
import tinykeys from "tinykeys"

import Navigation from "./Navigation"
import FocusTrap from "focus-trap-react"

const Header = () => {
  const [isNavToggled, toggleNav] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let unsubscribe = tinykeys(window, {
      Escape: () => {
        toggleNav(!isNavToggled)
      },
    })
    return () => {
      unsubscribe()
    }
  })

  return (
    <header className={`fixed top-0 z-50 w-full`}>
      <FocusTrap active={isNavToggled}>
        <div className="relative flex items-center justify-between pt-4 content">
          <Logo onClick={() => toggleNav(false)} />
          <button
            className="z-20 p-2 text-white rounded-xl focus:outline-none"
            onClick={() => toggleNav(!isNavToggled)}
          >
            <span className="sr-only">
              {isNavToggled ? "Close" : "Open"} Menu
            </span>
            <MenuIcon isToggled={isNavToggled} />
          </button>

          <Navigation
            isToggled={isNavToggled}
            title="Close Navigation"
            onElementClick={() => toggleNav(false)}
          />
        </div>
      </FocusTrap>
    </header>
  )
}

export default Header
